import './App.css';
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import Loader from './components/loader/Loader.jsx';
import Landing from './view/Home/Landing.jsx';
import NewDelhi from './components/Nav/destinations/North India/NewDelhi.js';
import Punjab from './components/Nav/destinations/North India/Punjab.js';
import Rajasthan from './components/Nav/destinations/North India/Rajasthan.js';
import UttarPradesh from './components/Nav/destinations/North India/UttarPradesh.js';
import MadhyaPradesh from './components/Nav/destinations/Central India/MadhyaPradesh.js';
import Kerala from './components/Nav/destinations/South India/Kerala.js';
import Karnataka from './components/Nav/destinations/South India/Karnataka.js';
import TamilNadu from './components/Nav/destinations/South India/TamilNadu.js';
import Telangana from './components/Nav/destinations/South India/Telangana.js';
import Andaman from './components/Nav/destinations/South India/AndamanIslands.js';
import Goa from './components/Nav/destinations/West India/Goa.js';
import Maharashtra from './components/Nav/destinations/West India/Maharashtra.js';
import Gujarat from './components/Nav/destinations/West India/Gujarat.js';
import Nagaland from './components/Nav/destinations/East India/Nagaland.js';
import Odisha from './components/Nav/destinations/East India/Odisha.js';
import Sikkim from './components/Nav/destinations/East India/Sikkim.js';
import Assam from './components/Nav/destinations/East India/Assam.js';
import WestBengal from './components/Nav/destinations/East India/WestBengal.js';
import Jammu from './components/Nav/destinations/The Himalayas/JammuKashmir.js';
import Himanchal from './components/Nav/destinations/The Himalayas/Himanchal.js';
import Uttarakhand from './components/Nav/destinations/The Himalayas/Uttarakhand.js';
import Ladakh from './components/Nav/destinations/The Himalayas/Ladakh.js';
import Nepal from './components/Nav/destinations/Beyond India/Nepal.js';
import Bhutan from './components/Nav/destinations/Beyond India/Bhutan.js';
import SriLanka from './components/Nav/destinations/Beyond India/SriLanka.js';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HashRouter>
      <div>
        {
          loading ? <Loader /> : (
            <div>
              <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
              </Helmet>
              <Routes>
                <Route path="/" exact element={<Landing/>} />
                <Route path="/new-delhi" element={<NewDelhi/>} />
                <Route path="/punjab" element={<Punjab/>} />
                <Route path="/rajasthan" element={<Rajasthan/>} />
                <Route path="/uttar-pradesh" element={<UttarPradesh/>} />
                <Route path="/madhya-pradesh" element={<MadhyaPradesh/>} />
                <Route path="/kerala" element={<Kerala/>} />
                <Route path="/karnataka" element={<Karnataka/>} />
                <Route path="/tamil-nadu" element={<TamilNadu/>} />
                <Route path="/telangana" element={<Telangana/>} />
                <Route path="/andaman" element={<Andaman/>} />
                <Route path="/goa" element={<Goa/>} />
                <Route path="/maharashtra" element={<Maharashtra/>} />
                <Route path="/gujarat" element={<Gujarat/>} />
                <Route path="/nagaland" element={<Nagaland/>} />
                <Route path="/odisha" element={<Odisha/>} />
                <Route path="/sikkim" element={<Sikkim/>} />
                <Route path="/assam" element={<Assam/>} />
                <Route path="/west-bengal" element={<WestBengal/>} />
                <Route path="/jammu-kashmir" element={<Jammu/>} />
                <Route path="/himanchal-pradesh" element={<Himanchal/>} />
                <Route path="/uttarakhand" element={<Uttarakhand/>} />
                <Route path="/ladakh" element={<Ladakh/>} />
                <Route path="/nepal" element={<Nepal/>} />
                <Route path="/bhutan" element={<Bhutan/>} />
                <Route path="/sri-lanka" element={<SriLanka/>} />
              </Routes>
            </div>
          )
        }
      </div>
    </HashRouter>
  );
}

export default App;
