import React from "react";
// Sections
import TopNavbar from "../../components/Nav/TopNavbar";
// import SocialLink from "../../components/Nav/SocialLink";
// import Header from "../../components/Sections/Header";
// import Services from "../../components/Sections/Services";
// import Projects from "../../components/Sections/Projects";
// import Blog from "../../components/Sections/Blog";
// import Pricing from "../../components/Sections/Pricing";
import Contact from "../../components/Sections/Contact";
import Footer from "../../components/Sections/Footer"
import BannerClouser from "../../components/Sections/Home/banner/BannerClouser";
// import Explore from "../../components/Sections/Home/service/ExploreTour";
import Carousel from "./carousel/Carousel";
import AboutUs from "./AboutUs/AboutUs";
import Brands from "./brands/Brands";
import Stay from "./stay/Stay";
import Video from "./Video/Video"
import CarouselNew from "./carousel_new/CarouselNew";
// import CustomerCarousel from "./customer_carousel/CustomerCarousel";
import Social from "../../components/Nav/Social";

export default function Landing() {
  return (
    <>
      <Social />
      <TopNavbar/>

      <BannerClouser />
      
      {/* <Header /> */}

      {/* <Explore/> */}
      <Brands/>
      <AboutUs/>
     <div className="pb-40  pt-24 mt-10 bg-gray-50 ">
     {/* <Projects /> */}
     <Stay/>
     </div>
     <Video />
      <div className=" pt-12   pb-32 " style={{ backgroundImage: ("url(/travels/banner/banner3.jpg)") }} >
        <div>
          <h2 className="text-center text-sm font-semibold text-gray-200 pb-4 ">TRAVEL FULLY COVERED</h2>
          <h2 className="text-center text-5xl  text-gray-200 pb-12 font-Courgette ">Historical Place</h2>
        </div>
        <Carousel />
      </div>

      {/* <CustomerCarousel/> */}
      <CarouselNew />

      {/* <Services /> */}
      {/* <Blog /> */}
      {/* <Pricing /> */}
      <Contact />
      <Footer />
    </>
  );
}


