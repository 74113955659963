// import React from 'react'

// function Stay() {
//   return (
//     <div>

//     </div>
//   )
// }

// export default Stay


import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './stay.css'

const Stay = () => {
    const slides = [
        { id: 1, src: '/travels/slider/Charminar02.jpg', title: "Charminar", description: "axaxc" },
        { id: 2, src: '/travels/slider/Hampi_01.jpg', title: "Hampi", description: "axaxc" },
        { id: 3, src: '/travels/slider/Jama_Masjid.jpg', title: "Jama Masjid", description: "axaxc" },
        { id: 4, src: '/travels/slider/Jodhpur.jpg', title: "Jodhpur fort", description: "axaxc" },
        { id: 5, src: '/travels/slider/Refort_11.jpg', title: "Refort", description: "axaxc" },
    ];

    return (

        <div className='md:mx-20 mx-4'>
            <div className=' flex '>
                <div className='md:w-40 w-24 h-0.5 my-auto bg-orange-400'></div>
                <p className=' ml-4 text-orange-500 text-md '>POPULAR DESTINATION </p>
            </div>
            <div className='md:grid md:grid-cols-2 py-6'>
                <h2 className='md:text-5xl text-3xl  font-Courgette md:font-Roboto_Slab md:text-start text-center'>TOP NOTCH DESTINATION</h2>
                <p className='md:ml-10 pt-6 font-Courgette md:font-Roboto_Slab md:text-start text-center'>Aperiam sociosqu urna praesent, tristique, corrupti condimentum asperiores platea ipsum ad arcu. Nostrud. Aut nostrum, ornare quas provident laoreet nesciunt.</p>
            </div>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2  gap-6'>
                <div className="relative   bg-white shadow-xl " data-aos="fade-up"
                    data-aos-duration="2000"  >
                    <img src='/travels/stay/stay3.jpg' alt='' className=' p-3  h-80 lg:h-[550px]   image-container ' />

                    <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                        <h3 className='md:text-2xl  font-Courgette  ' > Trek the Himalayas</h3>
                        <p>OUTDOORS</p>
                    </div>
                </div>

                <div className="relative   bg-white shadow-xl" data-aos="fade-up"
                    data-aos-duration="2000"   >
                    <img src='/travels/stay/stay2.jpg' alt='' className=' p-3  h-80 lg:h-[550px]  image-container   ' />

                    <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                        <h3 className='md:text-2xl  font-Courgette  ' > Mumbai through the Lens</h3>
                        <p>CULTURE & HERITAGE</p>
                    </div>
                </div>

                <div className='sm:hidden lg:block'>
                    <div className="relative   bg-white shadow-xl " data-aos="fade-up"
                        data-aos-duration="2000"  >

                        <img src='/travels/stay/stay6.jpg' alt='' className=' p-3 h-80 sm:h-[270px]  image-container  ' />


                        <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                            <h3 className='md:text-2xl  font-Courgette  ' > Deep Dive the Indian Ocean</h3>
                            <p>HONEYMOON</p>
                        </div>
                    </div>
                    <div className="relative   align-center pt-3  bg-white shadow-xl" data-aos="fade-up"
                        data-aos-duration="2000"  >

                        <img src='/travels/stay/stay5.jpg' alt='' className=' p-3  h-80 sm:h-[270px]  image-container  ' />


                        <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                            <h3 className='md:text-2xl  font-Courgette  ' > Yoga on the Ganga</h3>
                            <p>WELLNESS</p>
                        </div>
                    </div>
                </div>
                <div className="relative  lg:hidden sm:block hidden bg-white shadow-xl " data-aos="fade-up"
                    data-aos-duration="2000"  >

                    <img src='/travels/stay/stay4.jpg' alt='' className=' p-3 h-80 lg:h-[290px]  image-container  ' />


                    <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                        <h3 className='md:text-2xl  font-Courgette  ' > Trek the Himalayas</h3>
                        <p>OUTDOORS</p>
                    </div>
                </div>
                <div className="relative lg:hidden sm:block hidden  align-center pt-3  bg-white shadow-xl" data-aos="fade-up"
                    data-aos-duration="2000"  >

                    <img src='/travels/stay/stay5.jpg' alt='' className=' p-3  h-80 lg:h-[300px]  image-container  ' />


                    <div className=" absolute text-center  text-white py-4  w-full bg-black/50  bottom-[0%]" >
                        <h3 className='md:text-2xl  font-Courgette  ' > Trek the Himalayas</h3>
                        <p>OUTDOORS</p>
                    </div>
                </div>

            </div>
            <div className='mt-10  flex '>

                {/* <button className='hover:bg-orange-500 border border-orange-400 text-orange-400 hover:text-white px-20 rounded-sm mx-auto py-4 '>View More </button> */}
                <a href="#_" class="relative inline-flex items-center justify-center px-8 sm:px-16 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-purple-500 rounded-full shadow-md group mx-auto max-w-full text-center">
                    <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-purple-500 group-hover:translate-x-0 ease">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute flex items-center justify-center w-full h-full text-purple-600 transition-all duration-300 transform group-hover:translate-x-full ease">View More Destination</span>
                    <span class="relative invisible">Button Text</span>
                </a>

            </div>
        </div>
    );
};





export default Stay;