import React from 'react'
import ClientSlider from '../../../components/Elements/ClientSlider'

function Brands() {
    return (
        <div className="bg-gray-50 mb-20" style={{ padding: "50px 0" }
        }>
            <div className="container">
                <ClientSlider />
            </div></div>

    )
}

export default Brands
