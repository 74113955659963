




// import React, { useEffect, useState } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// // import Aos from 'aos';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const AboutUs = () => {
//     const settings = {
//         dots: false,
//         infinite: true,
//         // autoplay: false,
//         // autoplaySpeed: 6000,
//         speed: 4000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         nextArrow: <SampleNextArrow />,
//         prevArrow: <SamplePrevArrow />,
//         responsive: [
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 1
//                 }
//             },

//         ]
//     };

//     useEffect(() => {
//         AOS.init({
//             duration: 600,
//             delay: 390,
//         });
//     }, []);

//     const [hasMounted, setHasMounted] = useState(false);

//     useEffect(() => {
//         setHasMounted(true);
//     }, []);

//     if (!hasMounted) {
//         return null;
//     }

//     const slides = [
//         { id: 1, src: 'travels/explore/faimly.jpg', title: "Charminar", description: "axaxc" },
//         { id: 2, src: 'travels/explore/faimly.jpg', title: "Hampi", description: "axaxc" },
//         { id: 3, src: 'travels/explore/faimly.jpg', title: "Jama Masjid", description: "axaxc" },
//         { id: 4, src: 'travels/explore/faimly.jpg', title: "Jodhpur fort", description: "axaxc" },
//         { id: 5, src: 'travels/explore/faimly.jpg', title: "Refort", description: "axaxc" },
//     ];

//     return (
//         <div>
//             <Slider {...settings}>
//                 {slides.map((slide, index) => (
//                   <div key={index}>
//                       <div  className=' md:grid md:grid-cols-5 mx-24'>
//                         <div className='bg-gray-50 col-span-2 py-12'>
//                             <div className='text-center '>
//                                 <p className='text-xl '>Canada</p>
//                                 <h2 className='text-3xl pt-6'>BOOK TO INSPIRE </h2>
//                                 <h2 className='text-3xl pt-2'>YOUR TRAVELS </h2>
//                                 <p className='text-md pt-4'>WANDERLUST</p>
//                             </div>
//                             <p className='text-md px-10 pt-6 text-center'>Lorem ipsum dolor sit amet, no elitr tation delicata cum, mei in causae deseruisse. Has eruditi singulis principes ad, eam fuisset voluptua ea, pro ceteros adipisci in. Sed tempor adversarium ad, eum ne facete delectus. </p>
//                         </div>
//                         <div className='py-10 px-8 col-span-3  bg-gray-50'>
//                             <img src={slide.src} className='rounded-md h-[500px] w-[800px]' />
//                         </div>
//                     </div>
//                   </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// };

// function SampleNextArrow(props) {
//     const { onClick } = props;
//     return (
//         <div className="slick-arrow hidden md:block  right-[2%] absolute top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
//             {/* <p className=''>next</p> */}
//             <i class="fa fa-arrow-circle-right text-4xl text-gray-600 " aria-hidden="true"></i>
//         </div>
//     );
// }

// function SamplePrevArrow(props) {
//     const { onClick } = props;
//     return (
//         <div className="slick-arrow absolute md:block hidden left-[2%] top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
//             {/* <p className=''>back</p> */}

//             <i class="fa fa-arrow-circle-left text-4xl text-gray-600" aria-hidden="true"></i>

//         </div>
//     );
// }

// export default AboutUs;

import React, { useEffect, useState } from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../AboutUs/about.css'

const AboutUs = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    autoplayspeed: 1000,
                }
            },
        ]
    };





    const slides = [
        { id: 1, src: 'travels/home/about/about-one-img-1.e526a0a5.png', title: "Hampi", description: "axaxc" },
    ];

    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 390,
        });
    }, []);

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return null;
    }

    return (
        <div className="flex justify-center">
            <div className="max-w-7xl w-full">

                {slides.map((slide, index) => (
                    <div key={index}>
                        <div className='lg:grid lg:grid-cols-5 mx-6' data-aos="fade-up"
                            data-aos-duration="3000" >
                            <div className=' col-span-2 py-12'>
                                <div className='text-center'>
                                    <p className='text-xl font-Courgette '>Travel Experiance with miniature</p>
                                    <h2 className='heading-text pt-6 font-Courgette heading-text '>BOOK TO INSPIRE</h2>
                                    <h2 className='heading-text pt-4 font-Courgette heading-text'>YOUR TRAVELS</h2>
                                    <p className='text-md pt-4 font-Courgette '>WANDERLUST</p>
                                </div>
                                <p className='text-md px-10 pt-10 text-gray-500 text-center font-serif font-Courgette md:font-Roboto_Slab'>There are many variations of passages of available but the majority have suffered alteration in some form, by injected hum randomised words which don't look even slightly.</p>
                                <div className='flex mt-10'>
                                    <p className='bg-orange-500 hidden lg:block rounded-full  text-xs font-bold text-white mr-4 px-2 pt-1.5'>✓</p>
                                    <p className='hidden lg:block'>Invest in your simply neighborhood</p>
                                </div>
                                <div className='flex mt-3 '>
                                    <p className='bg-orange-500 hidden lg:block rounded-full  text-xs font-bold text-white mr-4 px-2 pt-1.5'>✓</p>
                                    <p className='hidden lg:block'>Largest global industrial business community</p>
                                </div>
                            </div>
                            <div className='md:py-10 md:px-8 col-span-3 relative '>
                                <img src={slide.src} className='rounded-md   w-full' data-aos="fade-up" data-aos-duration="3000" />
                                <div className='about-one__call bottom-[150px]  md:bottom-[250px] '  data-aos="fade-up" data-aos-duration="3000" >
                                    <img  src='https://png.pngtree.com/png-clipart/20230320/original/pngtree-circle-phone-call-icon-in-black-color-png-image_8997757.png' className='w-8 sm:hidden md:block '></img>

                                    <div className='ml-4'>
                                        <p className='text-xs sm:hidden md:block'>Book your toor now</p>
                                      <a href='tel:88792 66177 ' className='sm:hidden md:block'>  <p className='font-semibold'>  91 88792 66177</p></a>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                ))}

            </div>
        </div>
    );
};

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow hidden md:block right-[2%] absolute top-[50%] z-10 cursor-pointer" onClick={onClick}>
            <i className="fa fa-arrow-circle-right text-4xl text-gray-600" aria-hidden="true"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow absolute md:block hidden left-[2%] top-[50%] z-10 cursor-pointer" onClick={onClick}>
            <i className="fa fa-arrow-circle-left text-4xl text-gray-600" aria-hidden="true"></i>
        </div>
    );
}

export default AboutUs;
