import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../carousel_new/carouselnew.css';
import Aos from "aos";
import "aos/dist/aos.css";

const CarouselNew = (props) => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="carousel-container" style={{backgroundImage: `url('/travels/carousel/back1.jpg')`}}>
            <div className="carousel-wrapper" data-aos = 'fade-up'>
                <h2 className="carousel-title text-center text-sm font-Courgette text-gray-200 pb-4">Our Happy Customers</h2>
                <Slider {...settings}>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file1.jpg' alt='Person walking on a path' />
                    </div>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file2.jpg' alt='Woman sitting on a bench' />
                    </div>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file3.jpg' alt='Snow-covered mountain' />
                    </div>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file1.jpg' alt='Person playing guitar' />
                    </div>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file2.jpg' alt='Sushi rolls' />
                    </div>
                    <div className="image" data-aos='fade-up'>
                        <img src='./travels/carousel/file3.jpg' alt='Golden retriever' />
                    </div>
                </Slider>
            </div>
        </div>
    );
};
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow hidden md:block  right-[2%] absolute top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
            {/* <p className=''>next</p> */}
            <i class="fa fa-arrow-circle-right text-4xl text-white " aria-hidden="true"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow absolute md:block hidden left-[2%] top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
            {/* <p className=''>back</p> */}

            <i class="fa fa-arrow-circle-left text-4xl text-white" aria-hidden="true"></i>

        </div>
    );
}

export default CarouselNew;
