import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const WestBengal = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>West Bengal</h1>
                    <h2>Explore the Cultural Melting Pot of West Bengal</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/west-bengal/west-bengal.jpg" alt="west-bengal" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            West Bengal, located in eastern India, is known for its intellectual heritage, cultural diversity,
                            and architectural wonders. From the colonial charm of Kolkata to the serene hills of Darjeeling and
                            the mangrove forests of Sundarbans, West Bengal offers a plethora of experiences.
                        </p>
                        <p>
                            Begin your exploration in Kolkata (Calcutta), the capital city known for its literary history,
                            colonial architecture, and vibrant arts scene. Visit the Victoria Memorial, Howrah Bridge, and
                            Dakshineswar Kali Temple for a glimpse into the city's rich past and religious heritage.
                        </p>
                        <p>
                            Explore the Sundarbans, the world's largest mangrove forest and a UNESCO World Heritage site,
                            known for its Royal Bengal tigers and diverse wildlife. Take a boat safari to explore the dense forests,
                            creeks, and watchtowers amidst the tranquil surroundings.
                            <br />
                            Visit Darjeeling, a hill station known for its tea gardens and panoramic views of the Himalayas.
                            Ride the famous Darjeeling Himalayan Railway (Toy Train) to Ghum, visit the Padmaja Naidu Himalayan
                            Zoological Park, and enjoy a cup of Darjeeling tea amidst breathtaking vistas.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover West Bengal</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/west-bengal/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/west-bengal/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/west-bengal/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WestBengal;
