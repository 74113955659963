import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Kerala = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Kerala</h1>
                    <h2>Discover God's Own Country: Kerala</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/kerala/kerala.jpg" alt="kerala" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Kerala, often called God's Own Country, is a state in southern India known for its lush greenery,
                            backwaters, and vibrant cultural heritage. From serene beaches to tranquil hill stations and historic temples,
                            Kerala offers a unique blend of natural beauty and cultural richness.
                        </p>
                        <p>
                            Begin your journey in Kochi (Cochin), a port city known for its colonial architecture and bustling spice markets.
                            Explore the historic Fort Kochi area, where you can visit the St. Francis Church, Mattancherry Palace (Dutch Palace),
                            and the iconic Chinese fishing nets along the waterfront.
                        </p>
                        <p>
                            Visit Munnar, a hill station known for its sprawling tea plantations, misty mountains, and picturesque valleys.
                            Explore the Eravikulam National Park to spot the endangered Nilgiri Tahr and trek to the scenic viewpoints offering panoramic
                            views of the Western Ghats.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Kerala</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/kerala/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/kerala/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/kerala/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kerala;
