import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Goa = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Goa</h1>
                    <h2>Discover the Beach Paradise: Goa</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/goa/goa.jpg" alt="goa" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Goa, on the western coast of India, is renowned for its pristine beaches, vibrant nightlife,
                            and Portuguese heritage. From ancient churches to bustling markets and water sports, Goa offers
                            a perfect blend of relaxation and adventure.
                        </p>
                        <p>
                            Begin your journey in Old Goa (Velha Goa), a UNESCO World Heritage site, and visit the Basilica
                            of Bom Jesus, known for housing the mortal remains of St. Francis Xavier. Explore other historic
                            churches like Se Cathedral and Church of St. Cajetan.
                        </p>
                        <p>
                            Relax on the beautiful beaches of North Goa such as Calangute, Baga, and Anjuna,
                            known for their lively atmosphere, water sports, and beach shacks offering delicious
                            seafood and drinks. Visit the vibrant markets of Anjuna and Mapusa for souvenirs and local handicrafts.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Goa</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/goa/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/goa/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/goa/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Goa;
