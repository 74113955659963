// src/components/Nav/DropdownMenu.js

import React, { useState } from 'react';
import './dropdownmenu.css'; // Import custom CSS
import { Link } from 'react-router-dom';

const DropdownMenu = () => {
    const [showNorthIndia, setShowNorthIndia] = useState(false);
    const [showSouthIndia, setShowSouthIndia] = useState(false);
    const [showCentralIndia, setShowCentralIndia] = useState(false);
    const [showEastIndia, setShowEastIndia] = useState(false);
    const [showWestIndia, setShowWestIndia] = useState(false);
    const [showBeyondIndia, setShowBeyondIndia] = useState(false);
    const [showHimalayas, setShowHimalayas] = useState(false);

    

    return (
        <div className="relative inline-block text-left">
            <div className="group inline-block">
                <button className="dropdown-btn semiBold font15 pointer" style={{ padding: "10px 15px" }} >
                    <span>Destination</span>
                </button>
                <div className="absolute hidden pt-1 group-hover:block">
                    <div className="bg-white shadow-lg rounded-lg">
                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowNorthIndia(true)}
                            onMouseLeave={() => setShowNorthIndia(false)}
                        >
                            North India
                        </Link>
                        {showNorthIndia && (
                            <div
                                className="submenu"
                                onMouseEnter={() => setShowNorthIndia(true)}
                                onMouseLeave={() => setShowNorthIndia(false)}
                            >
                                <Link to="/new-delhi" className="dropdown-item">
                                    New Delhi
                                </Link>
                                <Link to="/punjab" className="dropdown-item">
                                    Punjab
                                </Link>
                                <Link to="/rajasthan" className="dropdown-item">
                                    Rajasthan
                                </Link>
                                <Link to="/uttar-pradesh" className="dropdown-item">
                                    Uttar Pradesh
                                </Link>
                            </div>
                        )}
                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowCentralIndia(true)}
                            onMouseLeave={() => setShowCentralIndia(false)}
                        >
                            Central India
                        </Link>
                        {showCentralIndia && (
                            <div
                                className="submenu submenu-central"
                                onMouseEnter={() => setShowCentralIndia(true)}
                                onMouseLeave={() => setShowCentralIndia(false)}
                            >
                                <Link to="/madhya-pradesh" className="dropdown-item">
                                    Madhya Pradesh
                                </Link>
                            </div>
                        )}

                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowSouthIndia(true)}
                            onMouseLeave={() => setShowSouthIndia(false)}
                        >
                            South India
                        </Link>
                        {showSouthIndia && (
                            <div
                                className="submenu submenu-south"
                                onMouseEnter={() => setShowSouthIndia(true)}
                                onMouseLeave={() => setShowSouthIndia(false)}
                            >
                                <Link to="/kerala" className="dropdown-item">
                                    Kerala
                                </Link>
                                <Link to="/tamil-nadu" className="dropdown-item">
                                    Tamil Nadu
                                </Link>
                                <Link to="/karnataka" className="dropdown-item">
                                    Karnataka
                                </Link>
                                <Link to="/telangana" className="dropdown-item">
                                    Telangana
                                </Link>
                                <Link to="/andaman" className="dropdown-item">
                                    Andaman Islands
                                </Link>
                            </div>
                        )}

                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowWestIndia(true)}
                            onMouseLeave={() => setShowWestIndia(false)}
                        >
                            West India
                        </Link>
                        {showWestIndia && (
                            <div
                                className="submenu submenu-west"
                                onMouseEnter={() => setShowWestIndia(true)}
                                onMouseLeave={() => setShowWestIndia(false)}
                            >
                                <Link to="/goa" className="dropdown-item">
                                    Goa
                                </Link>
                                <Link to="/gujarat" className="dropdown-item">
                                    Gujarat
                                </Link>
                                <Link to="/maharashtra" className="dropdown-item">
                                    Maharashtra
                                </Link>
                            </div>
                        )}

                        <Link
                            to="/"
                            className="dropdown-item "
                            onMouseEnter={() => setShowEastIndia(true)}
                            onMouseLeave={() => setShowEastIndia(false)}
                        >
                            East India
                        </Link>
                        {showEastIndia && (
                            <div
                                className="submenu submenu-east"
                                onMouseEnter={() => setShowEastIndia(true)}
                                onMouseLeave={() => setShowEastIndia(false)}
                            >
                                <Link to="/nagaland" className="dropdown-item">
                                    Nagaland
                                </Link>
                                <Link to="/assam" className="dropdown-item">
                                    Assam
                                </Link>
                                <Link to="/odisha" className="dropdown-item">
                                    Odisha
                                </Link>
                                <Link to="/sikkim" className="dropdown-item">
                                    Sikkim
                                </Link>
                                <Link to="/west-bengal" className="dropdown-item">
                                    West Bengal
                                </Link>
                            </div>
                        )}
                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowHimalayas(true)}
                            onMouseLeave={() => setShowHimalayas(false)}
                        >
                            The Himalayas
                        </Link>
                        {showHimalayas && (
                            <div
                                className="submenu submenu-himalayas"
                                onMouseEnter={() => setShowHimalayas(true)}
                                onMouseLeave={() => setShowHimalayas(false)}
                            >
                                <Link to="/himanchal-pradesh" className="dropdown-item">
                                    Himanchal Pradesh
                                </Link>
                                <Link to="/jammu-kashmir" className="dropdown-item">
                                    Jammu & Kashmir
                                </Link>
                                <Link to="/uttarakhand" className="dropdown-item">
                                    Uttrakhand
                                </Link>
                                <Link to="/ladakh" className="dropdown-item">
                                    Ladakh
                                </Link>
                            </div>
                        )}
                        <Link
                            to="/"
                            className="dropdown-item"
                            onMouseEnter={() => setShowBeyondIndia(true)}
                            onMouseLeave={() => setShowBeyondIndia(false)}
                        >
                            Beyond India
                        </Link>
                        {showBeyondIndia && (
                            <div
                                className="submenu submenu-beyond"
                                onMouseEnter={() => setShowBeyondIndia(true)}
                                onMouseLeave={() => setShowBeyondIndia(false)}
                            >
                                <Link to="/bhutan" className="dropdown-item">
                                    Bhutan
                                </Link>
                                <Link to="/nepal" className="dropdown-item">
                                    Nepal
                                </Link>
                                <Link to="/sri-lanka" className="dropdown-item">
                                    Sri Lanka
                                </Link>
                            </div>
                        )}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownMenu;
