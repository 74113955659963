import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Himanchal = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Himanchal Pradesh</h1>
                    <h2>Explore the Tranquil Beauty of Himachal Pradesh</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/himanchal/himanchal.jpg" alt="himanchal" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Himachal Pradesh, nestled in the Himalayas, is known for its scenic hill stations, pristine valleys,
                            and spiritual centers. From the bustling streets of Shimla to the peaceful retreats of Manali and
                            Dharamshala, Himachal Pradesh offers a blend of natural beauty and cultural richness.
                        </p>
                        <p>
                            Begin your journey in Shimla, the capital city known for its colonial architecture and panoramic
                            views of the Himalayas. Visit the Ridge, Mall Road, and Jakhu Temple for scenic vistas and serene
                            surroundings. Explore nearby attractions like Kufri and Chail for their natural beauty and adventure activities.
                        </p>
                        <p>
                            Visit Manali, a popular hill station known for its snow-capped mountains, apple orchards,
                            and the Beas River. Explore the Hadimba Devi Temple, Vashisht Hot Water Springs, and enjoy
                            adventure sports like paragliding and skiing in Solang Valley.
                            <br />
                            Explore Dharamshala and McLeod Ganj, known for their Tibetan culture and the residence of the
                            Dalai Lama. Visit the Namgyal Monastery, Bhagsunag Temple, and trek to Triund for stunning views
                            of the Dhauladhar range.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Himanchal Pradesh</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/himanchal/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/himanchal/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/himanchal/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Himanchal;
