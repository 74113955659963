import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Odisha = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Odisha</h1>
                    <h2>Discover the Cultural Tapestry of Odisha</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/odisha/odisha.jpg" alt="odisha" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Odisha, on the eastern coast of India, is renowned for its ancient temples, pristine beaches,
                            and vibrant art forms. From the temples of Bhubaneswar to the sun-kissed beaches of Puri and
                            the tribal villages of Koraput, Odisha offers a diverse range of cultural and natural experiences.
                        </p>
                        <p>
                            Begin your journey in Bhubaneswar, the capital city known as the "City of Temples." Visit the Lingaraj Temple,
                            a prominent Hindu pilgrimage site known for its towering spire and intricate carvings. Explore the ancient
                            temples of Konark, including the UNESCO World Heritage site of the Sun Temple.
                        </p>
                        <p>
                            Explore the tribal villages of Odisha in areas like Koraput and visit markets like the Kunduli Market to witness
                            traditional handicrafts, textiles, and indigenous art forms. Trek through the Simlipal National Park or visit
                            the Chilika Lake, a haven for migratory birds and the largest coastal lagoon in India.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Odisha</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/odisha/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/odisha/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/odisha/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Odisha;
