import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Karnataka = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Karnataka</h1>
                    <h2>Explore the Cultural Kaleidoscope: Karnataka</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/karnataka/karnataka.jpg" alt="karnataka" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Karnataka, located in southwest India, is known for its rich cultural heritage, historic monuments,
                            and scenic landscapes. From the palaces of Mysore to the ancient ruins of Hampi and the tech hub of Bengaluru,
                            Karnataka offers a diverse range of experiences.
                        </p>
                        <p>
                            Begin your exploration in Bengaluru (Bangalore), the capital city known as India's Silicon Valley. Explore the vibrant markets
                            of Brigade Road and Commercial Street, visit the historic Bangalore Palace, and stroll through the lush Lalbagh Botanical Garden.
                        </p>
                        <p>
                            Visit Mysore (Mysuru) and explore the majestic Mysore Palace, a blend of Indo-Saracenic architecture and exquisite craftsmanship.
                            Experience the vibrant festivities during the Dasara festival and witness the illuminated palace at night.

                            Explore the UNESCO World Heritage site of Hampi, once the capital of the Vijayanagara Empire. Marvel at the ruins of ancient temples,
                            palaces, and market complexes amidst the boulder-strewn landscape of Hampi's archaeological wonders.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Karnataka</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/karnataka/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/karnataka/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/karnataka/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Karnataka;
