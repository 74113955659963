import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Punjab = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Punjab</h1>
                    <h2>Explore the Vibrant State of Punjab</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/punjab/punjab.jpg" alt="Punjab" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Punjab, known as the land of five rivers, is a state in northern
                            India famous for its rich cultural heritage, agricultural prosperity,
                            and warm hospitality. From the Golden Temple in Amritsar to the historic
                            city of Patiala, Punjab offers a blend of spirituality, history, and scenic beauty.
                        </p>
                        <p>
                            Start your journey at the Golden Temple, the holiest shrine of Sikhism,
                            known for its stunning architecture and serene atmosphere. Explore the bustling
                            streets of Amritsar and savor traditional Punjabi cuisine such as makki di roti and sarson da saag.
                        </p>
                        <p>
                            Venture to Patiala to visit the majestic Qila Mubarak complex, a symbol of Patiala's
                            royal heritage. Marvel at its exquisite architecture and visit the Sheesh Mahal
                            (Palace of Mirrors) for a glimpse into the opulent lifestyle of Punjab's Maharajas.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Punjab</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/punjab/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/punjab/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/punjab/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Punjab;
