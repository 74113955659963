import Aos from 'aos';
import React, { useEffect } from 'react';
import '../Video/video.css'

const Video = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div className="aesthetic" style={{ position: "relative" }}>
            {/* Background Image */}
            <img
                src="./travels/video/background.jpg"
                className="img-fluid1"
                style={{ width: "100%", opacity: "0.7" }}
                alt="Monument"
            />
            <div
                className="text-overlay"
                style={{
                    position: "absolute",
                    top: "10%",  /* Adjust vertical positioning */
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    color: "#fff",  /* Text color */
                    fontSize: "2rem",  /* Adjust font size */
                    fontWeight: "bold",  /* Adjust font weight */
                                        
                }}
            >
                <h4 className="heading text-center text-sm font-semibold text-200 pb-4 " style={{ paddingTop: '10px' }}>Joyful Journeys</h4>
                <h4 className="sub-heading text-center text-sm font-Courgette text-200 pb-4 " style={{ paddingTop: '10px' }}>Tradition, Taste, Delight</h4>
                
            </div>
            {/* Video Overlay */}
            <div
                data-aos="fade-up"

                style={{
                    position: "absolute",
                    top: "54%",  /* Adjust vertical positioning */
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",  /* Adjust width of the video */
                    maxWidth: "1250px",  /* Limit maximum width */
                    borderRadius: "0",  /* Add border radius for a rounded look */
                    overflow: "hidden",  /* Hide overflowing video */
                    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",  /* Add shadow for depth */
                    backgroundColor: "transparent",  /* Set background color to transparent */
                    alignItems: 'center',
                    
                }}
            >
                <video
                    autoPlay
                    loop
                    muted
                    style={{ width: '100%' , objectFit : 'cover' }}
                >
                    <source src="./travels/video/video2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default Video;
