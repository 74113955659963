import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Telangana = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Telangana</h1>
                    <h2>Discover the Charms of Telangana</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/telangana/telangana.jpg" alt="telangana" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Telangana, a newly formed state in southern India, is known for its rich history, architectural wonders,
                            and vibrant culture. From the iconic Charminar in Hyderabad to the ancient temples of Warangal,
                            Telangana offers a glimpse into its glorious past and dynamic present.
                        </p>
                        <p>
                            Begin your journey in Hyderabad, the capital city known for its blend of tradition and modernity.
                            Visit the iconic Charminar, a symbol of Hyderabad, and explore the bustling markets of Laad Bazaar and
                            nearby Mecca Masjid. Experience the grandeur of the Golconda Fort and the Qutb Shahi Tombs.
                        </p>
                        <p>
                            Explore the ancient city of Warangal and visit the Warangal Fort, known for its impressive Kakatiya
                            architecture and the iconic stone gateway of the Thousand Pillar Temple. Discover the Ramappa Temple,
                            a UNESCO World Heritage site renowned for its intricate carvings and architectural brilliance.

                            <br />
                            Visit the serene Nagarjuna Sagar Dam and explore the Nagarjuna Sagar Wildlife Sanctuary, known for its
                            diverse wildlife and picturesque landscapes. Discover the cultural heritage of Telangana through its
                            vibrant festivals, arts, and crafts.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Telangana</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/telangana/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/telangana/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/telangana/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Telangana;
