import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SriLanka = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Sri Lanka</h1>
                    <h2>Explore the Pearl of the Indian Ocean: Sri Lanka</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/sri-lanka/sri-lanka.jpg" alt="sri-lanka" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Sri Lanka, an island nation south of India, is known for its rich history, diverse landscapes,
                            and vibrant culture. From ancient ruins and UNESCO World Heritage sites to pristine beaches and
                            wildlife sanctuaries, Sri Lanka offers a tapestry of experiences for travelers.
                        </p>
                        <p>
                            Begin your journey in Colombo, the capital city, and visit the National Museum, Gangaramaya Temple,
                            and the bustling Pettah Market. Explore the colonial architecture of the Fort area and enjoy a stroll
                            along Galle Face Green facing the Indian Ocean.
                        </p>
                        <p>
                            Travel to Kandy, known for its sacred Buddhist sites including the Temple of the Tooth Relic
                            (Sri Dalada Maligawa), a UNESCO World Heritage site. Experience a traditional Kandyan dance
                            performance and visit the Royal Botanical Gardens in Peradeniya.
                            <br />
                            Visit the ancient city of Anuradhapura, known for its ruins of ancient monasteries, palaces,
                            and stupas dating back to the 3rd century BC. Explore the rock fortress of Sigiriya, a UNESCO
                            site with ancient frescoes and panoramic views from the summit.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Sri Lanka</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sri-lanka/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sri-lanka/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sri-lanka/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SriLanka;
