import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Rajasthan = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Rajasthan</h1>
                    <h2>Explore the Royal State of Rajasthan</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/rajasthan/rajasthan.jpg" alt="rajasthan" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Rajasthan, the land of kings, is renowned for its majestic forts, palaces,
                            and vibrant culture. With its desert landscapes, colorful festivals, and rich history,
                            Rajasthan offers a glimpse into India's royal past.
                        </p>
                        <p>
                            Begin your exploration at the Amber Fort in Jaipur, a UNESCO World Heritage site known for its
                            impressive architecture and panoramic views of the surrounding hills. Explore the bustling bazaars
                            of Jaipur and shop for traditional handicrafts, jewelry, and textiles.
                        </p>
                        <p>
                            Visit Udaipur, the City of Lakes, and admire the stunning Lake Palace amidst the tranquil waters
                            of Lake Pichola. Explore the City Palace complex and Saheliyon ki Bari garden for a taste
                            of Rajputana grandeur and architectural marvels.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Rajasthan</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/rajasthan/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/rajasthan/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/rajasthan/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rajasthan;
