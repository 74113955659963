import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TamilNadu = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Tamil Nadu</h1>
                    <h2>Explore the Land of Temples: Tamil Nadu</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/tamil-nadu/tamil-nadu.jpg" alt="tamil-nadu" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Tamil Nadu, located in southern India, is renowned for its ancient temples, classical dance forms,
                            and vibrant culture. From the architectural marvels of Chennai to the spiritual aura of Madurai and
                            the tranquil beaches of Kanyakumari, Tamil Nadu offers a diverse array of experiences.
                        </p>
                        <p>
                            Begin your exploration at the UNESCO World Heritage site of Mahabalipuram, known for its rock-cut temples
                            and intricate sculptures dating back to the Pallava dynasty. Explore the Shore Temple, Arjuna's Penance,
                            and the Five Rathas to witness ancient Dravidian architecture.
                        </p>
                        <p>
                            Explore the hill station of Ooty (Udhagamandalam), nestled in the Nilgiri Hills, and enjoy the scenic
                            Nilgiri Mountain Railway journey. Visit the Botanical Gardens, Doddabetta Peak, and enjoy boating on
                            the picturesque Ooty Lake amidst serene surroundings.<br />
                            Visit Madurai, the cultural capital of Tamil Nadu, and explore the Meenakshi Amman Temple,
                            a masterpiece of Dravidian architecture adorned with vibrant sculptures and towering gopurams
                            (gateway towers). Attend the evening temple ceremony to experience the city's spiritual fervor.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Tamil Nadu</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/tamil-nadu/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/tamil-nadu/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/tamil-nadu/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TamilNadu;
