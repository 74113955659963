import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NewDelhi = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>New Delhi</h1>
                    <h2>Explore India's Capital City</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/new-delhi/delhi.jpg" alt="New Delhi" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            New Delhi, the capital city of India, is a bustling metropolis that
                            seamlessly blends modernity with history. It is home to some of the
                            most iconic landmarks like the Red Fort, Qutub Minar, and India
                            Gate, each telling tales of India's rich past and vibrant culture.
                        </p>
                        <p>
                            Beyond its historical monuments, New Delhi offers a diverse
                            experience with its vibrant markets, delicious street food, and
                            thriving arts and culture scene. Whether you're exploring the
                            narrow lanes of Old Delhi or strolling through the lush greenery of
                            Lodi Gardens, there's something for everyone in this dynamic city.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover New Delhi</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/new-delhi/c3.jpg" alt="Carousel  1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/new-delhi/c2.jpg" alt="Carousel  2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/new-delhi/c1.jpg" alt="Carousel  3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDelhi;
