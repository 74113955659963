import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Nagaland = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Nagaland</h1>
                    <h2>Explore the Cultural Riches of Nagaland</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/nagaland/nagaland.jpg" alt="nagaland" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Nagaland, nestled in the northeastern part of India, is known for its rich tribal culture,
                            picturesque landscapes, and vibrant festivals. From traditional Naga villages to scenic hills
                            and valleys, Nagaland offers a glimpse into its unique heritage and natural beauty.
                        </p>
                        <p>
                            Begin your exploration in Kohima, the capital city, and visit the War Cemetery, a poignant reminder
                            of World War II. Explore the Kohima Village for its traditional Naga houses and handicrafts, and
                            attend the Hornbill Festival in December to experience the diverse tribal cultures of Nagaland.
                        </p>
                        <p>
                            Visit the village of Khonoma, known for its conservation efforts and traditional agriculture practices.
                            Trek to Dzukou Valley, famous for its lush green hills and seasonal flowers, offering breathtaking views
                            and opportunities for nature enthusiasts and photographers.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Nagaland</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nagaland/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nagaland/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nagaland/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nagaland;
