import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Ladakh = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Ladakh</h1>
                    <h2>Explore the Land of High Passes: Ladakh</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/ladakh/ladakh.jpg" alt="ladakh" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Ladakh, located in the northernmost part of India, is known for its barren landscapes,
                            high-altitude passes, and Tibetan Buddhist monasteries. From the ancient monasteries
                            of Leh to the serene lakes of Pangong and Tso Moriri, Ladakh offers a unique blend of
                            spirituality and adventure.
                        </p>
                        <p>
                            Begin your journey in Leh, the capital city, and visit historic landmarks like Leh Palace,
                            Shanti Stupa, and the ancient monasteries of Thiksey and Hemis. Explore the Leh market for
                            Tibetan artifacts, handicrafts, and traditional Ladakhi cuisine.
                        </p>
                        <p>
                            Drive to Nubra Valley via Khardung La Pass, one of the highest motorable passes in the world,
                            and visit Diskit Monastery with its giant Buddha statue. Enjoy a camel ride in the sand dunes
                            of Hunder and explore the scenic beauty of the valley.
                            <br />
                            Visit Pangong Tso Lake, famous for its turquoise waters and dramatic mountain backdrop, as seen
                            in the movie "3 Idiots." Stay overnight in camps near the lake and witness the changing colors
                            of the lake during sunrise and sunset.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Ladakh</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/ladakh/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/ladakh/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/ladakh/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ladakh;
