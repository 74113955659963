import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Uttarakhand = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Uttarakhand</h1>
                    <h2>Discover the Spiritual and Natural Wonders of Uttarakhand</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/uttarakhand/uttarakhand.jpg" alt="uttarakhand" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Uttarakhand, known as Devbhoomi (Land of Gods), is famous for its pilgrimage sites,
                            Himalayan peaks, and pristine forests. From the spiritual cities of Haridwar and Rishikesh
                            to the scenic beauty of Nainital and the adventure hub of Auli, Uttarakhand offers diverse
                            experiences for every traveler.
                        </p>
                        <p>
                            Begin your exploration in Haridwar, a sacred city on the banks of the Ganges River, known for its
                            ghats and evening Ganga Aarti. Visit the Har Ki Pauri ghat, Mansa Devi Temple, and Chandi Devi
                            Temple for spiritual experiences and panoramic views of the city.
                        </p>
                        <p>
                            Experience yoga and meditation in Rishikesh, the Yoga Capital of the World, nestled in the foothills
                            of the Himalayas. Visit the famous Beatles Ashram, Ram Jhula, and Laxman Jhula bridges, and embark
                            on adventurous activities like river rafting and trekking.
                            <br />
                            Visit the hill station of Nainital, known for its pear-shaped lake surrounded by hills and colonial-era buildings.
                            Enjoy boating on Naini Lake, visit Naina Devi Temple, and explore nearby attractions like Snow View Point
                            and Naina Peak for stunning views of the Himalayas.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Uttarakhand</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttarakhand/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttarakhand/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttarakhand/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Uttarakhand;
