import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Jammu = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Jammu & Kashmir</h1>
                    <h2>Explore the Paradise on Earth: Jammu & Kashmir</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/jammu/jammu.jpg" alt="jammu" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Jammu & Kashmir, often referred to as "Paradise on Earth," is known for its breathtaking landscapes,
                            serene lakes, and Mughal gardens. From the Dal Lake in Srinagar to the snow-covered peaks of Gulmarg
                            and the ancient temples of Jammu, Jammu & Kashmir offers a mesmerizing blend of natural beauty and
                            cultural heritage.
                        </p>
                        <p>
                            Begin your exploration in Srinagar, the summer capital, and experience a shikara ride on Dal Lake
                            amidst floating gardens and houseboats. Visit the Mughal gardens of Shalimar Bagh, Nishat Bagh, and
                            Chashme Shahi for their terraced lawns, fountains, and stunning views.
                        </p>
                        <p>
                            Explore the ancient city of Jammu and visit the Vaishno Devi Temple, one of the holiest Hindu pilgrimage
                            sites dedicated to Goddess Vaishno Devi. Explore the historic Bahu Fort, Raghunath Temple, and the tranquil
                            Mansar Lake surrounded by forests.
                            <br />
                            Visit Gulmarg, a popular skiing destination and hill station known for its Gulmarg Gondola,
                            the highest cable car in the world. Enjoy skiing, snowboarding, and snowshoeing in winter, and trekking
                            or golfing amidst alpine meadows in summer.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Jammu & Kashmir</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/jammu/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/jammu/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/jammu/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Jammu;
