import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Assam = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Assam</h1>
                    <h2>Explore the Rich Heritage of Assam</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/assam/assam.jpg" alt="assam" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Assam, located in northeastern India, is known for its tea gardens, wildlife sanctuaries, and vibrant cultural
                            heritage. From the Brahmaputra River to the Kaziranga National Park and ancient temples, Assam offers a blend
                            of natural beauty and cultural richness.
                        </p>
                        <p>
                            Begin your journey in Guwahati, the gateway to Assam, and visit the Kamakhya Temple, a prominent Hindu pilgrimage
                            site dedicated to Goddess Kamakhya. Take a river cruise on the Brahmaputra River to admire the scenic beauty and
                            spot migratory birds and river dolphins.
                        </p>
                        <p>
                            Explore Kaziranga National Park, a UNESCO World Heritage site and home to the one-horned rhinoceros. Embark
                            on safari adventures to spot wildlife such as tigers, elephants, and wild water buffalo amidst lush greenery
                            and vast grasslands.

                            <br />
                            Visit Majuli, the world's largest river island on the Brahmaputra River, known for its Vaishnavite monasteries
                            (satras) and traditional Assamese culture. Experience the vibrant festivals of Bihu and explore the tea gardens
                            of Jorhat and Dibrugarh, known for their aromatic Assam tea.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Assam</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/assam/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/assam/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/assam/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Assam;
