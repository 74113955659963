import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Bhutan = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Bhutan</h1>
                    <h2>Explore the Land of Happiness: Bhutan</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/bhutan/bhutan.jpg" alt="bhutan" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Bhutan, nestled in the eastern Himalayas, is known for its pristine landscapes, ancient monasteries,
                            and Gross National Happiness index. From the fortresses (dzongs) of Paro and Thimphu to the scenic valleys
                            of Punakha and the cultural richness of Bumthang, Bhutan offers a unique blend of spirituality and natural beauty.
                        </p>
                        <p>
                            Begin your journey in Paro and visit the iconic Tiger's Nest Monastery (Taktsang Palphug), perched
                            on a cliff overlooking the Paro Valley. Explore the Paro Dzong, a fortress-monastery known for its
                            stunning architecture and religious significance.
                        </p>
                        <p>
                            Travel to Thimphu, the capital city, and visit the Tashichho Dzong, the seat of the Bhutanese government
                            and monastic body. Explore the National Memorial Chorten, the National Library, and the Folk Heritage
                            Museum to delve into Bhutanese culture and history.
                            <br />
                            Experience the serene Punakha Valley and visit the Punakha Dzong, one of Bhutan's most beautiful
                            fortresses located at the confluence of the Pho Chhu and Mo Chhu rivers. Explore the Wangdue Phodrang
                            Dzong and the Chimi Lhakhang temple dedicated to the "Divine Madman."
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Bhutan</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/bhutan/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/bhutan/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/bhutan/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bhutan;
