


import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Aos from 'aos';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../stay/stay.css'

const Carousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },

        ]
    };

    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 390,
        });
    }, []);

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return null;
    }

    const slides = [
        { id: 1, src: '/travels/slider/char_minar.jpg', title: "Charminar", description: "axaxc" },
        { id: 2, src: '/travels/slider/hampi.jpg', title: "Hampi", description: "axaxc" },
        { id: 3, src: '/travels/slider/jamamasjid.jpg', title: "Jama Masjid", description: "axaxc" },
        { id: 4, src: '/travels/slider/jodhpur_fort.jpg', title: "Jodhpur fort", description: "axaxc" },
        { id: 5, src: '/travels/slider/redfort.jpg', title: "Refort", description: "axaxc" },
    ];

    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="relative px-3 sm:mx-28 ml-10  align-center " data-aos="fade-up"
                        data-aos-duration="3000"  >
                        {/* <div className='h-[500px] rounded-xl' style={{ backgroundImage: (`url${(slide.src)}`) }} >

                        </div> */}
                         {/* <div
                            class="absolute mx-3 rounded-xl xs:bg-transparent xs:bg-black/40  ltr:xs:bg-gradient-to-r rtl:xs:bg-gradient-to-l inset-0  sm:from-black/20 sm:to-white/5 ltr:sm:bg-gradient-to-r "
                        ></div> */}
                        <img src={slide.src} alt={slide.title} className='rounded-xl h-80 sm:h-[440px]   image-container ' />

                       
                        <div className=" absolute  text-white left-[25%] sm:left-[20%]   top-[85%]" style={{ transform: "translate(-50%, -50%)" }}>

                            <h3 className='md:text-2xl  font-bold font-Roboto_Slab' >{slide.title}</h3>
                            <p>{slide.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow hidden md:block  right-[2%] absolute top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
            {/* <p className=''>next</p> */}
            <i class="fa fa-arrow-circle-right text-4xl text-white " aria-hidden="true"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-arrow absolute md:block hidden left-[2%] top-[50%] bottom-[50%] z-10 cursor-pointer " onClick={onClick}>
            {/* <p className=''>back</p> */}

            <i class="fa fa-arrow-circle-left text-4xl text-white" aria-hidden="true"></i>

        </div>
    );
}

export default Carousel;