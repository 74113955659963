import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Sikkim = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Sikkim</h1>
                    <h2>Explore the Serene Beauty of Sikkim</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/sikkim/sikkim.jpg" alt="sikkim" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Sikkim, nestled in the Himalayas, is known for its pristine landscapes, Buddhist monasteries,
                            and vibrant culture. From the capital city of Gangtok to the high-altitude lakes and snow-capped peaks,
                            Sikkim offers a peaceful retreat amidst breathtaking natural beauty.
                        </p>
                        <p>
                            Begin your exploration in Gangtok, the capital city, and visit the Enchey Monastery known for its serene
                            ambiance and panoramic views of the Himalayas. Explore the Namgyal Institute of Tibetology to learn about
                            Tibetan Buddhism and traditional art forms.
                        </p>
                        <p>
                            Visit Tsomgo Lake (Changu Lake), a glacial lake located at an altitude of 12,313 feet, offering stunning views and
                            yak rides amidst snow-covered mountains. Explore the ancient Rumtek Monastery, the largest monastery in Sikkim and a
                            center of Tibetan Buddhism.
                            <br />
                            Drive along the famous Silk Route to witness alpine meadows, waterfalls, and stunning views of the Kanchenjunga range.
                            Visit Pelling to admire the majestic Kanchenjunga Falls and the Pemayangtse Monastery, one of the oldest monasteries
                            in Sikkim.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Sikkim</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sikkim/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sikkim/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/sikkim/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sikkim;
