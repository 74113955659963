import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Maharashtra = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Maharashtra</h1>
                    <h2>Explore the Diversity of Maharashtra</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/maharashtra/maharashtra.jpg" alt="maharashtra" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Maharashtra, located in western India, is known for its bustling cities, historic landmarks,
                            and cultural diversity. From the vibrant streets of Mumbai to the ancient caves of Ajanta and Ellora,
                            Maharashtra offers a blend of modernity and tradition.
                        </p>
                        <p>
                            Begin your journey in Mumbai, the financial capital of India, and visit iconic landmarks such as the
                            Gateway of India and Marine Drive. Explore the vibrant neighborhoods of Colaba, Bandra, and Juhu for
                            shopping, dining, and nightlife experiences.
                        </p>
                        <p>
                            Visit the Elephanta Caves, a UNESCO World Heritage site located on Elephanta Island in Mumbai Harbour,
                            known for its rock-cut temples dedicated to Lord Shiva. Explore the Ajanta and Ellora Caves near Aurangabad,
                            famous for their intricate sculptures and ancient Buddhist architecture.
                            <br />
                            Explore Pune, the cultural capital of Maharashtra, and visit the historic Aga Khan Palace, where Mahatma Gandhi
                            was imprisoned during India's freedom struggle. Discover the serene hill stations of Lonavala and Mahabaleshwar
                            for their scenic beauty and pleasant climate.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Maharashtra</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/maharashtra/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/maharashtra/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/maharashtra/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Maharashtra;
