import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const UttarPradesh = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Uttar Pradesh</h1>
                    <h2>Explore the Cultural Heartland of Uttar Pradesh</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/uttar-pradesh/uttar-pradesh.jpg" alt="uttar-pradesh" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Uttar Pradesh, located in northern India, is known for its rich cultural heritage, religious significance,
                            and architectural marvels. From the Taj Mahal in Agra to the spiritual city of Varanasi, Uttar Pradesh
                            offers a diverse tapestry of experiences.
                        </p>
                        <p>
                            Start your journey at the Taj Mahal, one of the Seven Wonders of the World, and marvel at its breathtaking beauty
                            and intricate marble craftsmanship. Explore the Agra Fort and Fatehpur Sikri, both UNESCO World Heritage sites,
                            for insights into Mughal architecture and history.
                        </p>
                        <p>
                            Head to Varanasi, the spiritual capital of India, and experience the mesmerizing Ganga Aarti ceremony
                            on the ghats of the River Ganges. Take a boat ride on the river at sunrise to witness the city come
                            alive with devotion and rituals.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Uttar Pradesh</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttar-pradesh/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttar-pradesh/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/uttar-pradesh/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UttarPradesh;
