import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Nepal = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Nepal</h1>
                    <h2>Explore the Paradise on Earth: nepal & Kashmir</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/nepal/nepal.jpg" alt="nepal" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Nepal, home to the highest peaks in the world including Mount Everest, is known for its majestic
                            mountains, ancient temples, and diverse cultures. From the bustling streets of Kathmandu to the
                            serene lakes of Pokhara and the wildlife of Chitwan, Nepal offers a variety of experiences for travelers.
                        </p>
                        <p>
                            Begin your exploration in Kathmandu, the capital city, and visit Durbar Square, a UNESCO World
                            Heritage site showcasing ancient palaces, temples, and courtyards. Explore the sacred Hindu temple
                            of Pashupatinath and the Buddhist stupas of Swayambhunath (Monkey Temple) and Boudhanath.
                        </p>
                        <p>
                            Travel to Pokhara, known for its tranquil lakes and stunning views of the Annapurna and Dhaulagiri ranges.
                            Enjoy boating on Phewa Lake, visit the World Peace Pagoda, and trek to Sarangkot for panoramic views of the
                            Himalayas and sunrise or sunset.
                            <br />
                            Explore the Chitwan National Park, a UNESCO World Heritage site, for wildlife safaris to spot rhinos,
                            Bengal tigers, and elephants amidst dense forests and grasslands. Experience Tharu culture with traditional
                            dance performances and village tours.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Nepal</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nepal/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nepal/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/nepal/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nepal;
