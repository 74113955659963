import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MadhyaPradesh = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Madhya Pradesh</h1>
                    <h2>Explore the Heart of India: Madhya Pradesh</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/madhya-pradesh/madhya-pradesh.jpg" alt="madhya-pradesh" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Madhya Pradesh, often referred to as the heart of India, is a state known for its rich cultural
                            heritage, ancient temples, and diverse wildlife. From the majestic forts of Gwalior to the UNESCO World
                            Heritage sites of Khajuraho, Madhya Pradesh offers a glimpse into India's historical and natural treasures.
                        </p>
                        <p>
                            Begin your exploration at Khajuraho, renowned for its exquisite temples adorned with intricate erotic sculptures.
                            Explore the Western Group of Temples, a UNESCO site, to admire the architectural brilliance and detailed carvings
                            that depict life in medieval India.
                        </p>
                        <p>
                            Explore the wildlife sanctuaries and national parks of Madhya Pradesh, such as Bandhavgarh and Kanha National Parks,
                            renowned for their tiger populations and diverse flora and fauna. Embark on safaris to witness the majestic Bengal
                            tigers and other wildlife species in their natural habitats.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Madhya Pradesh</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/madhya-pradesh/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/madhya-pradesh/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/madhya-pradesh/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MadhyaPradesh;
