import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Gujarat = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Gujarat</h1>
                    <h2>Explore the Vibrant State of Gujarat</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/gujarat/gujarat.jpg" alt="gujarat" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            Gujarat, located on the western coast of India, is known for its rich cultural heritage,
                            historic monuments, and vibrant festivals. From the bustling city life of Ahmedabad to
                            the serene white desert of Kutch, Gujarat offers a diverse range of experiences.

                        </p>
                        <p>
                            Begin your exploration in Ahmedabad, the largest city and former capital of Gujarat.
                            Visit the Sabarmati Ashram, where Mahatma Gandhi lived, and explore the Sidi Saiyyed Mosque
                            known for its intricate lattice work. Don't miss the vibrant markets of Law Garden and Manek Chowk.
                        </p>
                        <p>
                            Visit the UNESCO World Heritage site of Champaner-Pavagadh Archaeological Park, known for its ancient temples,
                            forts, and stepwells dating back to the 8th century. Explore the majestic Jama Masjid and the hilltop Kalika
                            Mata Temple for panoramic views of the surrounding landscape.
                            <br />
                            Explore the Great Rann of Kutch, a salt marsh desert, especially during the Rann Utsav festival when the desert
                            comes alive with cultural performances, handicraft stalls, and camel safaris. Visit the nearby villages to
                            witness traditional art forms like embroidery and tie-dye.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Gujarat</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/gujarat/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/gujarat/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/gujarat/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gujarat;
