import React from 'react';
import Social from '../../Social';
import '../North India/delhi.css';
import TopNavbar from '../../TopNavbar';
import Slider from 'react-slick'; // Import React Slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Andaman = () => {
    // Settings for Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>
            <Social />
            <TopNavbar />
            <div className="destinations-page">
                <div className="container1 inter-heading">
                    <h1>Andaman Islands</h1>
                    <h2>Explore the Tropical Paradise: Andaman Islands</h2>
                    <div className="image-container1">
                        <img src="./travels/destinations/andaman/andaman.jpg" alt="andaman" />
                    </div>
                    <div className="description1 inter-regular">
                        <p>
                            The Andaman Islands, located in the Bay of Bengal, are a group of picturesque islands known for
                            their pristine beaches, coral reefs, and rich marine life. From water sports to historical sites
                            and indigenous cultures, the Andaman Islands offer a serene escape into nature's lap.
                        </p>
                        <p>
                            Begin your journey in Port Blair, the capital city, and visit the Cellular Jail National Memorial, a poignant reminder
                            of India's struggle for independence. Explore the Anthropological Museum to learn about the indigenous tribes of the
                            Andaman and Nicobar Islands.
                        </p>
                        <p>
                            EVisit Havelock Island (Swaraj Dweep) and enjoy its stunning Radhanagar Beach, known for its crystal-clear waters
                            and powdery white sands. Experience snorkeling or scuba diving to explore vibrant coral reefs and encounter
                            exotic marine species.
                            <br />
                            Explore Neil Island (Shaheed Dweep) and relax on its pristine beaches such as Bharatpur and Laxmanpur.
                            Enjoy activities like glass-bottom boat rides and cycling tours to discover the island's natural beauty
                            and local life.
                        </p>
                    </div>

                    {/* Heading and Subheading for Carousel */}
                    <div className="carousel-heading inter-heading">
                        <h3>Discover Andaman Islands</h3>
                        <p><i>Explore the beauty and history</i></p>
                    </div>

                    {/* Slick Carousel */}
                    <div className="carousel-container-delhi">
                        <Slider {...settings}>
                            <div className="carousel-item">
                                <img src="./travels/destinations/andaman/c3.jpg" alt="Carousel 1" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/andaman/c2.jpg" alt="Carousel 2" />
                            </div>
                            <div className="carousel-item">
                                <img src="./travels/destinations/andaman/c1.jpg" alt="Carousel 3" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Andaman;
